type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

const select = <T extends Array<any>>(
  array: T,
  identifier: keyof ArrayElement<T>,
  value?: any
) => {
  const index = array.findIndex((e) => e[identifier] === value);
  if (index === -1)
    return {
      pop: () => {},
      update: () => {},
      set: (e: ArrayElement<T>) => {
        array.push(e);
      },
    };
  const element = array[index];

  const pop = () => {
    array.splice(index, 1);
  };

  const set = (e: ArrayElement<T>) => {
    array.splice(index, 1, e);
  };

  const update = (key: string, newValue?: any) => {
    const newobj = { ...element };
    var k = key.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    k = k.replace(/^\./, ""); // strip a leading dot
    var pList = k.split(".");

    let schema = newobj;
    const len = pList.length;
    for (var i = 0; i < len - 1; i++) {
      var elem = pList[i];
      if (!schema[elem]) schema[elem] = {};
      schema = schema[elem];
    }

    schema[pList[len - 1]] = newValue;

    array.splice(index, 1, newobj);
  };
  return { pop, update, element, set };
};

export default select;
