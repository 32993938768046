//import { createTask, PAction } from "behave-react";
/*
function* signup(action: PAction<string>) {
  const task = createTask("fetching", "auth");

  yield* task.start();
  try {
    yield* task.success(false);
  } catch (e) {
    if (e) yield* task.fail(e);
  }
}
*/
function* Saga() {
  //yield* takeLatest<SessionSagas>("session/signup", signup);
}

export default Saga;
