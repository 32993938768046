import { useRef, useState } from "react";
import { chevron } from "../../../assets/icons";
import useOnClickOutside from "../../../hooks/use-clickOutside";
import useThemedIcon from "../../../hooks/use-themed-icon";
import "./styles.css";
interface DropDownProps {
  placeholder?: string;
  options: string[];
  onSelect: (n: number) => void;
  selected?: number;
  disabled?: boolean;
  className?: string;
}

const DropDown = ({
  placeholder,
  options,
  selected,
  onSelect,
  className,
}: DropDownProps) => {
  const [open, isOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => isOpen(false));

  const icon = useThemedIcon();
  return (
    <div className={`dropdown col ${className}`} ref={ref}>
      <div
        className="row cv fillw fillh ph-s"
        onClick={() => isOpen((t) => !t)}
      >
        <p
          className="f1 "
          style={{ opacity: selected === undefined ? 0.5 : 1 }}
        >
          {selected === undefined ? placeholder : options[selected ?? 0]}
        </p>
        <img
          className="icon xs"
          alt="arrow"
          src={icon("chevron")}
          style={{ transform: `rotateZ(${open ? 180 : 0}deg)` }}
        />
      </div>
      {open && (
        <div className="options">
          {options.map((p, i) => (
            <p
              onClick={() => {
                isOpen(false);
                onSelect(i);
              }}
            >
              {p}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropDown;
