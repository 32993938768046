import CryptoJS from "crypto-js";

export const cryptData = (
  m: Partial<MetaData>,
  k: keyof MetaData,
  key: string
) => {
  const j = JSON.stringify(m[k]);
  const b64 = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(j));
  let c64 = "";
  for (let i = 0; i < b64.length; i++) {
    c64 =
      String.fromCharCode(b64.charCodeAt(i) + key.charCodeAt(i % key.length)) +
      c64;
  }

  return c64;
};

export const uncryptData = (m: string, key: string) => {
  let c64 = "";
  for (let i = m.length - 1; i >= 0; i--) {
    c64 =
      c64 +
      String.fromCharCode(
        m.charCodeAt(i) - key.charCodeAt((m.length - i - 1) % key.length)
      );
  }

  const u64 = CryptoJS.enc.Base64.parse(c64).toString(CryptoJS.enc.Utf8);

  return u64;
};
