import { useBehave } from "behave-react";
import { useRef } from "react";
import useOnClickOutside from "../../../../hooks/use-clickOutside";
import { SessionSelect } from "../../../../store/modules/session";
import { UserAction } from "../../../../store/modules/user";
import "./styles.css";

interface AddTaskPopupProps {
  onClose: () => void;
}

const AddTaskPopup = ({ onClose }: AddTaskPopupProps) => {
  const { select, dispatch } = useBehave();
  const ref = useRef<HTMLDivElement>(null);
  const taskActions = select(SessionSelect.taskActions);
  useOnClickOutside(ref, onClose);

  const handleAddTask = (task: TaskAction) => {
    dispatch(UserAction.addTask({ done: false, ...task }));
    onClose();
  };

  return (
    <div className="col ch cv addTaskBack">
      <div className="addTask col g-m pv-l ph-xl" ref={ref}>
        <h3 className="center">Add new activity</h3>

        <div className="col g-m fillw gridList">
          {taskActions?.map((e) => (
            <p
              className="xl point fillw elementList"
              key={e.id}
              onClick={() => handleAddTask(e)}
            >
              {e.title}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddTaskPopup;
