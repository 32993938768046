import moment from "moment";
import { RootState } from "../..";

export const loadingTheme = (state: RootState) => state.session.loaders?.theme;
export const theme = (state: RootState) => state.session.theme;
export const quotes = (state: RootState) => state.session.quotes;
export const taskActions = (state: RootState) => state.session.tasksActions;
export const onboardingDone = (state: RootState) =>
  state.session.onboardingDone;
export const dailyQuote = (state: RootState) => {
  const index = moment().dayOfYear() % (state.session.quotes?.length ?? 0);
  console.log(state.session.quotes);
  return state.session.quotes?.find((e) => e.id === index.toFixed(0));
};
