import useTheme from "../../../hooks/use-theme";
import "./styles.css";
const VideoPlayer = () => {
  const { theme } = useTheme(true);

  return (
    <div className="videoContainer">
      <video
        className="videoPlayer"
        loop
        muted
        autoPlay
        playsInline
        key={theme}
      >
        <source
          src={`${process.env.PUBLIC_URL}assets/videos/main-${theme}.mp4`}
        />
      </video>
    </div>
  );
};

export default VideoPlayer;
