import { useState } from "react";
import "./styles.css";

interface MoodSelectorProps {
  onSelect: (n: number) => void;
  selected?: number;
}

const MoodSelector = ({ onSelect, selected }: MoodSelectorProps) => {
  return (
    <div className="row cv moodSelector mv-xl">
      {[0, 1, 2, 3, 4, 5].map((i) => (
        <div
          onClick={() => onSelect(i)}
          className={i === selected || selected === undefined ? "" : "hide"}
        >
          <div>
            <div style={{ height: `${(100 * i) / 5 + 10}%` }} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default MoodSelector;
