import { useBehave } from "behave-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { UserSelect } from "../../../store/modules/user";
import { UID } from "../../../utils/id-generator";
import "./styles.css";

type States = "in" | "out" | "hold";

interface BreatheAnimationProps {
  onComplete: (session: BreatheSession) => void;
}

const BreatheAnimation = ({ onComplete }: BreatheAnimationProps) => {
  const [state, setState] = useState<States>();
  const [sessionLenght, setSessionLenght] = useState(0);

  const { select } = useBehave();
  const timings = select(UserSelect.breatheSettings);

  const titles: { [key in States]: string } = {
    in: "Breathe in",
    out: "Breath out",
    hold: "Hold in",
  };

  useEffect(() => {
    inale();
  }, []);

  useEffect(() => {
    if (state === "in" && sessionLenght > timings.lenght) {
      setState(undefined);
      onComplete({
        date: moment().format("MM-DD-yyyy"),
        id: UID(),
        duration: sessionLenght,
      });
    }
  }, [state, sessionLenght]);

  const inale = () => {
    setState("in");
    setTimeout(keep, timings.in * 1000);
  };

  const keep = () => {
    setState("hold");
    setTimeout(exale, timings.hold * 1000);
  };

  const exale = () => {
    setState("out");
    setTimeout(inale, timings.out * 1000);
    setSessionLenght(sessionLenght + timings.in + timings.hold + timings.out);
  };

  return (
    <div className="breatheAnimation col ch cv">
      <div>
        <h3>{state ? titles[state ?? "hold"] : "Completed!"}</h3>
      </div>
      <svg
        style={{ transitionDuration: `${timings[state ?? "hold"] as number}s` }}
        className={state}
        width="100%"
        height="100%"
        viewBox="0 0 500 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          style={{
            transitionDuration: `${timings[state ?? "hold"] as number}s`,
          }}
          fill={timings.color}
          stroke={timings.color}
          d="M434.5,312Q374,374,312,414.5Q250,455,174.5,428Q99,401,73,325.5Q47,250,78,179.5Q109,109,179.5,67Q250,25,308,79.5Q366,134,430.5,192Q495,250,434.5,312Z"
          strokeWidth="7"
        />
      </svg>
    </div>
  );
};

export default BreatheAnimation;
