import { createTask, PAction, putAction, takeLatest } from "behave-react";
import moment from "moment";
import { call, select } from "typed-redux-saga";
import { UserAction, UserSagas, UserSelect } from ".";
import { RootState } from "../..";
import ArraySelect from "../../../utils/array-utils";
import { cryptData } from "../../../utils/safe-data";

function* getUser() {
  const task = createTask("fetching-user");
  yield task.start();
  try {
    yield task.success(true);
  } catch (e) {
    if (e) yield task.fail(e);
  }
}

function* trackMood(action: PAction<MoodTrack>) {
  const task = createTask("creating", "mood");
  yield task.start();
  try {
    const data = (yield select(UserSelect.metadata)) as MetaData;
    const moods = [...(data.moods ?? [])];
    ArraySelect(moods, "id", action.payload.id).set(action.payload);

    yield updateMetadata({ moods }, "moods");
    yield* putAction(UserAction.setMood(action.payload));
    yield task.success(true);
  } catch (e) {
    if (e) yield task.fail(e);
  }
}

function* deleteMood(action: PAction<string>) {
  const task = createTask("deleting", action.payload);
  yield task.start();
  try {
    const data = (yield select(UserSelect.metadata)) as MetaData;
    const moods = [...(data.moods ?? [])];
    ArraySelect(moods, "id", action.payload).pop();
    yield updateMetadata({ moods }, "moods");
    yield* putAction(UserAction.removeeMood(action.payload));
    yield task.success(true);
  } catch (e) {
    if (e) yield task.fail(e);
  }
}

function* addTask(action: PAction<Task>) {
  const task = createTask("updating", "tasks");
  yield task.start();
  try {
    const data = (yield select(UserSelect.metadata)) as MetaData;
    const taskDays = [...(data?.tasks ?? [])];
    const tasks = [
      ...(ArraySelect(taskDays, "date", moment().format("MM-DD-yyyy")).element
        ?.tasks ?? []),
    ];
    tasks.push(action.payload);

    if (tasks.length <= 1)
      taskDays[0] = { date: moment().format("MM-DD-yyyy"), tasks };
    else
      ArraySelect(taskDays, "date", moment().format("MM-DD-yyyy")).update(
        "tasks",
        tasks
      );

    yield* putAction(UserAction.updateTodayTasks(taskDays));
    yield updateMetadata({ tasks: taskDays }, "tasks");

    yield task.success(true);
  } catch (e) {
    console.log(e);
    if (e) yield task.fail(e);
  }
}

function* toogleTask(action: PAction<string>) {
  const task = createTask("updating", "tasks");
  yield task.start();
  try {
    const data = (yield select(UserSelect.metadata)) as MetaData;
    const taskDays = [...(data?.tasks ?? [])];
    const tasks = [
      ...(ArraySelect(taskDays, "date", moment().format("MM-DD-yyyy")).element
        ?.tasks ?? []),
    ];
    const status = !ArraySelect(tasks, "id", action.payload).element?.done;

    ArraySelect(tasks, "id", action.payload).update("done", status);
    ArraySelect(taskDays, "date", moment().format("MM-DD-yyyy")).update(
      "tasks",
      tasks
    );

    yield* putAction(UserAction.updateTodayTasks(taskDays));
    yield updateMetadata({ tasks: taskDays }, "tasks");

    yield task.success(true);
  } catch (e) {
    console.log(e);
    if (e) yield task.fail(e);
  }
}

function* deleteTask(action: PAction<string>) {
  const task = createTask("deleting", "tasks");
  yield task.start();
  try {
    const data = (yield select(UserSelect.metadata)) as MetaData;
    const taskDays = [...(data?.tasks ?? [])];
    const tasks = [
      ...(ArraySelect(taskDays, "date", moment().format("MM-DD-yyyy")).element
        ?.tasks ?? []),
    ];

    ArraySelect(tasks, "id", action.payload).pop();
    ArraySelect(taskDays, "date", moment().format("MM-DD-yyyy")).update(
      "tasks",
      tasks
    );

    yield* putAction(UserAction.updateTodayTasks(taskDays));
    yield updateMetadata({ tasks: taskDays }, "tasks");

    yield task.success(true);
  } catch (e) {
    console.log(e);
    if (e) yield task.fail(e);
  }
}

function* addNote(action: PAction<Note>) {
  const task = createTask("creating", "note");
  yield task.start();
  try {
    const data = (yield select(UserSelect.metadata)) as MetaData;
    const notes = [...(data.notes ?? [])];
    ArraySelect(notes, "date", moment().format("MM-DD-yyyy")).set(
      action.payload
    );

    yield* putAction(UserAction.setNotes(notes));
    yield updateMetadata({ notes }, "notes");
    yield task.success(true);
  } catch (e) {
    if (e) yield task.fail(e);
  }
}

function* updateBreathSettings(action: PAction<BreathSettings>) {
  const task = createTask("updating", "settings");
  yield task.start();
  try {
    yield* putAction(UserAction.setBreathSetings(action.payload));
    yield updateMetadata(
      { breatheSettings: action.payload },
      "breatheSettings"
    );
    yield task.success(true);
  } catch (e) {
    if (e) yield task.fail(e);
  }
}

function* saveSession(action: PAction<BreatheSession>) {
  const task = createTask("creating", "session");
  yield task.start();
  try {
    const data = (yield select(UserSelect.metadata)) as MetaData;
    const sessions = [...(data.breathSessions ?? []), action.payload];
    yield* putAction(UserAction.updateBreathSessions(sessions));
    yield updateMetadata({ breathSessions: sessions }, "breathSessions");
    yield task.success(true);
  } catch (e) {
    if (e) yield task.fail(e);
  }
}

function* likeNote(action: PAction<string>) {
  const task = createTask("updating", "note");
  yield task.start();
  try {
    const data = (yield select(UserSelect.metadata)) as MetaData;
    const notes = [...(data?.notes ?? [])];

    const status = !ArraySelect(notes, "date", action.payload).element?.like;

    const note = ArraySelect(notes, "date", action.payload).element;

    if (note) ArraySelect(notes, "date", action.payload).update("like", status);
    else notes.push({ date: action.payload, comment: "", like: true });

    yield* putAction(UserAction.setNotes(notes));
    yield updateMetadata({ notes }, "notes");

    yield task.success(true);
  } catch (e) {
    if (e) yield task.fail(e);
  }
}

function* updateMetadata(action: Partial<MetaData>, key: keyof MetaData) {
  const user = (yield select((state: RootState) => state.user.user)) as User;
  
  const data = cryptData(action, key, user.email); //TODO: check if i can get the user id
  console.log(data);
  yield call(user.updateMetaData, { [key]: data });
}

function* Saga() {
  yield* takeLatest<UserSagas>("user/getUser", getUser);
  yield* takeLatest<UserSagas>("user/trackMood", trackMood);
  yield* takeLatest<UserSagas>("user/deleteMood", deleteMood);
  yield* takeLatest<UserSagas>("user/addTask", addTask);
  yield* takeLatest<UserSagas>("user/deleteTask", deleteTask);
  yield* takeLatest<UserSagas>("user/toogleTask", toogleTask);
  yield* takeLatest<UserSagas>("user/addNote", addNote);
  yield* takeLatest<UserSagas>(
    "user/updateBreathSettings",
    updateBreathSettings
  );
  yield* takeLatest<UserSagas>("user/saveSession", saveSession);
  yield* takeLatest<UserSagas>("user/likeNote", likeNote);
}

export default Saga;
