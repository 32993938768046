import { useBehave } from "behave-react";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WebPlaybackSDK, PlayerDevice } from "react-spotify-web-playback-sdk";
import useThemedIcon from "../../../hooks/use-themed-icon";
import { UserSelect } from "../../../store/modules/user";
import ThemeSelector from "../theme-selector";
import "./styles.css";

const ControlPanel = () => {
  const { select } = useBehave();
  const icon = useThemedIcon();
  const navigate = useNavigate();
  const [date, setDate] = useState(Date.now());
  const [playlist, showPlaylist] = useState(false);

  const user = select(UserSelect.user);

  useEffect(() => {
    const time = setInterval(() => setDate(Date.now()), 30000);
    return () => clearTimeout(time);
  }, []);

  window.onSpotifyWebPlaybackSDKReady = () => {
    const token =
      "BQAdyy5Hu_zQeu1swP_tNavDXVzf5yfgVicTtrJGbTVJotItGVKKgk6cB18Tacby4CBNqG6YPUnLx8Vg1MsmLpCkTkyfxAX9IBl-mTtMeY8j3nRw-pqnurvUXQkAKpTgaFPd1P-nYZpbfFXIuSrVb3QOp56mevshsAuQ8cDC";
    const player = new Spotify.Player({
      name: "Web Playback SDK Quick Start Player",
      getOAuthToken: (cb) => {
        cb(token);
      },
    });
  };

  const routeTo = (r: string, logged?: boolean) => {
    if (logged && !user?.loggedIn) navigate("/sign-up");
    else navigate(r);
  };

  return (
    <div className="col controlPanel mv-xl pv-xl">
      <p className="date">
        {moment(new Date(date).toISOString()).format("ddd DD MMM yyyy")}
      </p>
      <p className="time xl bordered">
        {moment(new Date(date).toISOString()).format("hh:mm a")}
      </p>
      <div className="row btn cv mv-xs" onClick={() => routeTo("/mood", true)}>
        <img src={icon("mood")} alt="mood" />
        <p className="mh-m">Mood Tracker</p>
      </div>
      <div
        className="row btn cv mv-xs"
        onClick={() => routeTo("/breathe", true)}
      >
        <img src={icon("breath")} alt="breath" />
        <p className="mh-m">Breathe</p>
      </div>
      <div className="row btn cv mv-xs" onClick={() => routeTo("/quote", true)}>
        <img src={icon("quote")} alt="quote" />
        <p className="mh-m">Daily quote</p>
      </div>
      <div className="row btn cv mv-xs" onClick={() => showPlaylist((t) => !t)}>
        <img src={icon("musicIcon")} alt="quote" />
        <p className="mh-m">Music</p>
      </div>

      <div className="spotifyWrapper">
        <iframe
          id="spotifyEmbed"
          style={{ display: playlist ? "block" : "none" }}
          className="spotifyEmbed"
          title="Spotify"
          src="https://open.spotify.com/embed/playlist/21rBuS5XKVCc7bd2raLoC0"
          width="100%"
          height="380"
          frameBorder="0"
        />
      </div>

      <p className="mv-s themeLabel">Look:</p>
      <ThemeSelector />
    </div>
  );
};

export default ControlPanel;
