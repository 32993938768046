import { useBehave, useTask, useTaskCallback } from "behave-react";
import moment from "moment";
import { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { MoodSelector } from "../../components/layout";
import useThemedIcon from "../../hooks/use-themed-icon";
import { SessionSelect } from "../../store/modules/session";
import { UserAction, UserSelect } from "../../store/modules/user";
import { UID } from "../../utils/id-generator";
import AddTaskPopup from "./components/add-task-popup";

import CalendarPage from "./components/calendar";
import MoodItem from "./components/mood-item";

import "./styles.css";
const MoodPage = () => {
  const { dispatch, select } = useBehave();
  const image = useThemedIcon();
  const [currentMood, setCurrentMood] = useState<number>();
  const [note, setNote] = useState("");
  const [completed, setCompleted] = useState(false);
  const navigate = useNavigate();
  const shouldAsk = select(UserSelect.askMood);
  const loading = useTask("creating", "mood");
  const data = select(UserSelect.metadata);
  const [date, setDate] = useState(moment().format("MM-DD-yyyy"));
  const [toEdit, settoEdit] = useState<MoodTrack>();
  const quote = select(SessionSelect.dailyQuote);
  const [addTask, showAddTask] = useState(false);

  const tasks = data?.tasks?.find(
    (e) =>
      moment(date, "MM-DD-yyyy").format("MM-DD-yyyy") ===
      moment(e.date, "MM-DD-yyyy").format("MM-DD-yyyy")
  )?.tasks;
  const moods = data?.moods?.filter(
    (e) =>
      moment(date, "MM-DD-yyyy").format("MM-DD-yyyy") ===
      moment(new Date(e.datePrecise).toISOString()).format("MM-DD-yyyy")
  );
  const dayNote = data?.notes?.find(
    (e) =>
      moment(date, "MM-DD-yyyy").format("MM-DD-yyyy") ===
      moment(e.date, "MM-DD-yyyy").format("MM-DD-yyyy")
  );
  const breathes = data?.breathSessions
    ?.filter(
      (e) =>
        moment(date, "MM-DD-yyyy").format("MM-DD-yyyy") ===
        moment(e.date, "MM-DD-yyyy").format("MM-DD-yyyy")
    )
    .reverse();
  useEffect(() => {
    setCompleted(true);
  }, [shouldAsk]);

  useTaskCallback(loading, { success: () => setCompleted(true) });

  const addMood = () => {
    dispatch(
      UserAction.trackMood({
        date: toEdit?.date ?? moment().format("MM-DD-yyyy"),
        datePrecise: toEdit?.datePrecise ?? Date.now(),
        note: note,
        value: currentMood!,
        id: toEdit?.id ?? UID(),
      })
    );
    settoEdit(undefined);
  };

  const handleNewMood = () => {
    setCompleted(false);
    setNote("");
  };

  const handleEdit = (m: MoodTrack) => {
    settoEdit(m);
    setCompleted(false);
    setCurrentMood(undefined);
    setNote(m.note);
  };
  return (
    <div className="fh fw col ch cv overlay fade-in ">
      {!completed ? (
        <div className="col ch ">
          {!currentMood && <h3 className="center">How are you feeling?</h3>}
          <MoodSelector selected={currentMood} onSelect={setCurrentMood} />
          {currentMood !== undefined ? (
            <div className="col fillw ch">
              <h3 className="center">Why do you feel like this?</h3>
              <div className="col fillw mv-l sw ch">
                <textarea
                  className="fillw noteWrite"
                  placeholder="Start writing here..."
                  onChange={(e) => setNote(e.target.value)}
                />

                <button className="mv-xl" type="submit" onClick={addMood}>
                  {loading.isLoading ? "Saving..." : "Done"}
                </button>
              </div>
            </div>
          ) : (
            date && <button onClick={() => setCompleted(true)}>Skip</button>
          )}
        </div>
      ) : (
        <>
          <div className="fillw container containerParent">
            <div className="row fillw ch actualContainer ">
              <CalendarPage onDateChange={setDate} />
              <span className="mh-xl" />
              <span className="mh-l" />

              <div className="col  actionsColum scrollable">
                <p className="point right fillw uppercase">
                  {moment(date, "MM-DD-yyyy").format("ddd DD MMM yyyy")}
                </p>
                <h4 className="mv-m">Moods</h4>
                {date === moment().format("MM-DD-yyyy") && (
                  <p className="point right fillw" onClick={handleNewMood}>
                    Add new
                  </p>
                )}
                <div className="  ">
                  <div className="moods col g-l scrollable ">
                    {moods?.length ? (
                      moods.map((e) => (
                        <MoodItem mood={e} onEdit={handleEdit} />
                      ))
                    ) : (
                      <p className=" center st">
                        No moods registered for this date
                      </p>
                    )}
                  </div>
                </div>
                <span className="mv-l" />
                <div className="col g-m">
                  <h4>Activities</h4>

                  <div
                    className="row cv g-m point"
                    onClick={() => showAddTask(true)}
                  >
                    <img className="icon s" src={image("add")} alt="add" />
                    <p className="xl">Add activity</p>
                  </div>
                  {tasks?.map((e) => (
                    <div className="row cv g-m point taskItem">
                      <p className="xl">{e.title}</p>
                      <img
                        className={`icon s ${e.done ? "checked" : ""}`}
                        src={image("delete")}
                        onClick={() => dispatch(UserAction.deleteTask(e.id))}
                        alt="add"
                      />
                    </div>
                  ))}
                </div>
                <span className="mv-l" />
                {(dayNote?.like || !!dayNote?.comment) && (
                  <>
                    <div
                      className="row g-s cv"
                      onClick={() => navigate("/quote?day=" + date)}
                    >
                      <h4>Daily Quote</h4>
                      <img className="icon xs " src={image("edit")} alt="add" />
                    </div>
                    <p className="quoteMood">"{quote?.quote.trim()}"</p>
                    <span className="mv-s" />
                    <p className="">{dayNote?.comment}</p>
                    <span className="mv-l" />
                  </>
                )}
                <div className="col g-m">
                  <h4>Breathing Sessions</h4>

                  <div
                    className="row cv g-m point"
                    onClick={() => navigate("/breathe")}
                  >
                    <img className="icon s" src={image("add")} alt="check" />
                    <p className="xl">Start breathing</p>
                  </div>
                  {breathes?.map((s) => (
                    <div className="row cv g-m">
                      <img
                        className="icon s"
                        src={image("breatheClear")}
                        alt="check"
                      />
                      <p className="xl">{`${Math.floor(
                        s.duration / 60
                      )}min ${Math.floor(s.duration % 60)} sec`}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {addTask && <AddTaskPopup onClose={() => showAddTask(false)} />}
    </div>
  );
};

export default MoodPage;
