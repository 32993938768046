import { Route, Routes, Navigate } from "react-router-dom";
import { LoginPage, MainPage, PrivacyPage, SignupPage } from "../../../pages";
import OnBoarding from "../../../pages/onboarding";

const UnauthorizedNavigation = () => {
  return (
    <Routes>
      <Route path="/*" element={<MainPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/sign-up" element={<SignupPage />} />
      <Route path="/tutorial" element={<OnBoarding />} />
      <Route path="/privacy" element={<PrivacyPage />} />
    </Routes>
  );
};

export default UnauthorizedNavigation;
