import { Header } from "../../components/layout";

const PrivacyPage = () => {
  return (
    <>
      <Header />
      <div className="fillh fillw col  container g-m">
        <span className="mv-xl" />
        <h1>PRIVACY POLICY</h1>
        <p>
          We value privacy and take all the necessary measures to protect it.
          This privacy policy is constantly updated as we roll out new services.
        </p>

        <h2>PERSONAL DATA</h2>
        <p>
          We store your email address and data generated on our platform, which
          is encrypted and is only accessible by you. We do so in order to let
          you access your data from multiple devices. Your data is automatically
          backed up offsite in secure servers. Member data, such as email and
          password, is secured using industry best practices. We force HTTPS,
          meaning data between websites and our servers is always encrypted.
          Data stored in our database is encrypted at rest.
        </p>
        <h2>DELETION OF PERSONAL DATA</h2>

        <p>
          At any time you can contact us at hello@gardi.io to request the
          cancellation of your account and full deletion of your data.
        </p>
        <h2>SERVICE PROVIDERS</h2>
        <p>Memberstack: Membership and data management.</p>
        <p>Plausible: Privacy-first analytics without cookies.</p>
        <p>Firebase: Hosting.</p>
      </div>
    </>
  );
};

export default PrivacyPage;
