import { behaveStore, poolReducer } from "behave-react";
import { combineReducers } from "redux";
import SessionModule, { SessionSaga } from "./modules/session";
import UserModule, { UserSaga } from "./modules/user";

const rootReducer = combineReducers({
  ...poolReducer(),
  session: SessionModule,
  user: UserModule,
});

const { store, persistor } = behaveStore({
  persistKey: "gardi-app",
  reducers: rootReducer,
  sagas: [SessionSaga, UserSaga],
  devtool: true,
});

export type RootState = ReturnType<typeof rootReducer>;
const Behave = { store, persistor };
export default Behave;
