export const randomOrderNumber = () => {
  return "xxxxxxxxxx"
    .replace(/[x]/g, () => {
      return ((Math.random() * 16) | 0).toString(16);
    })
    .toUpperCase();
};

export const UID = () => {
  return "xxxxxxxxxxxxxxx".replace(/[x]/g, () => {
    return ((Math.random() * 16) | 0).toString(16);
  });
};
