import moment from "moment";
import { RootState } from "../..";

export const user = (state: RootState) => state.user.user;
export const metadata = (state: RootState) => state.user.metadata;
export const askMood = (state: RootState) =>
  (state.user.metadata ?? { moods: [] }).moods?.findIndex(
    (e) =>
      moment(new Date(e.datePrecise).toISOString()).format("MM-DD-yyyy") ===
      moment().format("MM-DD-yyyy")
  ) === -1;

export const breatheSettings = (state: RootState) =>
  state.user.metadata?.breatheSettings ?? {
    color: "#212121",
    hold: 5,
    in: 3,
    lenght: 240,
    out: 7,
  };
