import { Behavior } from "behave-react";
import Navigation from "../../components/navigation";
import { BrowserRouter } from "react-router-dom";
import Store from "../../store";

const App = () => {
  return (
    <Behavior store={Store.store} persistor={Store.persistor}>
      <BrowserRouter>
        <Navigation />
      </BrowserRouter>
    </Behavior>
  );
};

export default App;
