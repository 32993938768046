import { Navigate, Route, Routes } from "react-router-dom";
import { ControlPanel, Header, VideoPlayer } from "../../components/layout";
import BreathePage from "../breathe";
import DailyQuote from "../daily-quote";
import MoodPage from "../mood";
import OnBoarding from "../onboarding";

import "./styles.css";

const MainPage = () => {
  return (
    <div className="fh fw">
      <Header />
      <div className="main fillh container relative col ch ">
        <VideoPlayer />
        <ControlPanel />
        <Routes>
          <Route path="/quote" element={<DailyQuote />} />
          <Route path="/mood" element={<MoodPage />} />
          <Route path="/breathe" element={<BreathePage />} />
          <Route path="/:other" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </div>
  );
};

export default MainPage;
