import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useOnClickOutside from "../../../hooks/use-clickOutside";
import useMemberstack from "../../../hooks/use-memberstack";
import useThemedIcon from "../../../hooks/use-themed-icon";
import "./styles.css";
const Header = () => {
  const icon = useThemedIcon();
  const navigate = useNavigate();
  const [popup, showPopup] = useState(false);
  const { user } = useMemberstack();
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => showPopup(false));
  return (
    <div className="header fw p-l row cv absolute">
      <img
        className=" point logo"
        onClick={() => navigate("/")}
        src={icon("logo")}
        alt="logo"
      />
      <span className="f1" />
      <div className="relative buttonContainer">
        {user?.loggedIn ? (
          <img
            onClick={() => showPopup((s) => !s)}
            className="icon hoverable s"
            src={icon("profile")}
            alt="profile"
          />
        ) : (
          <img
            onClick={() => showPopup((s) => !s)}
            className="icon hoverable s "
            src={icon("settings")}
            alt="settings"
          />
        )}

        <div
          ref={ref}
          className="popup col p-m g-m"
          style={{ display: popup ? undefined : "none" }}
        >
          <div className="arrow" />
          <a href="#ms-profile">User settings</a>
          {!user?.loggedIn && <a href="/login">Login</a>}
          {!user?.loggedIn && <a href="/sign-up">Sign Up</a>}
          <a href="https://airtable.com/shrRDvdfG9F5txqg8" target="__blank">
            Share Feedback
          </a>
          <a href="/tutorial">How it works</a>
          <a href="/privacy">Privacy</a>
        </div>
      </div>
    </div>
  );
};

export default Header;
