import { useBehave } from "behave-react";
import moment from "moment";
import useThemedIcon from "../../../../hooks/use-themed-icon";
import { UserAction } from "../../../../store/modules/user";
import "./styles.css";

interface MoodItemProps {
  mood: MoodTrack;
  onEdit: (m: MoodTrack) => void;
}

const MoodItem = ({ mood, onEdit }: MoodItemProps) => {
  const icon = useThemedIcon();
  const { dispatch } = useBehave();
  const { date, datePrecise, value, id, note } = mood;
  return (
    <div key={id} className="moodItem row g-m">
      <div className="col g-m">
        <div className="value">
          <div style={{ height: `${(100 * value) / 5 + 10}%` }} />
        </div>
        <div className="row ch cv g-s">
          <img
            className="point icon xs"
            onClick={() => onEdit(mood)}
            src={icon("editClear")}
            alt="edit"
          />
          <img
            className="point icon xs"
            src={icon("delete")}
            onClick={() => dispatch(UserAction.deleteMood(id!))}
            alt="edit"
          />
        </div>
      </div>

      <div className="col f1">
        <p className="xl pv-xs ">{note}</p>
        <p className="xs">{moment(new Date(datePrecise).toISOString()).format("HH:mm")}</p>
      </div>
    </div>
  );
};

export default MoodItem;
