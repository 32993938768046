import "./styles.css";

interface SnackbarProps {
  content: string;
  delay?: number;
  show?: boolean;
}

const Snackbar = ({ content, delay, show }: SnackbarProps) => {
  return (
    <div className="snackbar" style={{ bottom: !show ? -100 : 24 }}>
      <p>{content}</p>
    </div>
  );
};

export default Snackbar;
