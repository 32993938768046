import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { VideoPlayer } from "../../components/layout";
import useThemedIcon from "../../hooks/use-themed-icon";
import "./styles.css";
const LoginPage = () => {
  const image = useThemedIcon();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    (window as any)?.MemberStack?.reload();
  }, []);

  return (
    <div className="fh fw col ch cv">
      <div className="container" key="video-intro">
        <VideoPlayer />
      </div>

      <div className="loginContainer ch cv fillh fw col absolute">
        <form data-ms-form="login">
          <div className="col p-s">
            <Link className="close" to="/">
              <img src={image("close")} className="icon  xs hoverable" alt="close-icon" />
            </Link>
            <h4 className="xl bold center mv-l">Gardi</h4>
            <h3>Log in</h3>
            <h6 className="mv-xs">Welcome to Gardi! Please enter your details.</h6>
            <span className="mv-s" />
            <input data-ms-member="email" className="mv-s" placeholder="Email" />
            <div className="row cv relative">
              <input
                data-ms-member="password"
                className="fillw mv-s min0"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
              />
              <img
                onClick={() => setShowPassword(!showPassword)}
                src={image("password")}
                className="icon absolute passwordIcon xs hoverable"
                alt="password"
              />
            </div>
            <span className="mv-m" />
            <button type="submit">Login</button>

            <p className="xl semi mv-l center">
              Don't have an account?
              <Link to="/sign-up" className="bold mh-xs">
                Sign up
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
