import Airtable from "airtable";
import { useBehave } from "behave-react";
import { useEffect } from "react";
import { SessionAction, SessionSelect } from "../../store/modules/session";

const useAirtable = () => {
  const base = new Airtable({ apiKey: "keyDSsMI7YZgZINXx" }).base(
    "apphfbmdmfGzZQPSj"
  );
  const { dispatch, select } = useBehave();
  const quotes = select(SessionSelect.quotes);
  const tasks = select(SessionSelect.taskActions);

  useEffect(() => {
    const quotes: DailyQuote[] = [];
    base("Quotes")
      .select({
        view: "Grid view",
      })
      .eachPage(
        (records, fetchNextPage) => {
          records.forEach((record, i) => {
            if (record.get("Quote"))
              quotes.push({
                id: (quotes.length + 1).toFixed(0),
                quote: record.get("Quote") as string,
                author: record.get("Author") as string,
              });
          });
          fetchNextPage();
        },
        (err) => {
          dispatch(SessionAction.setQuotes(quotes));
        }
      );
  }, []);

  useEffect(() => {
    const tasks: TaskAction[] = [];
    base("TaskActions")
      .select({
        view: "Grid view",
      })
      .eachPage(
        (records, fetchNextPage) => {
          records.forEach((record, i) =>
            tasks.push({
              id: record.get("Id") as string,
              title: record.get("Name") as string,
            })
          );
          fetchNextPage();
        },
        (err) => {
          dispatch(SessionAction.setTasks(tasks));
        }
      );
  }, []);

  return { quotes, tasks };
};

export default useAirtable;
