import { useBehave } from "behave-react";
import { useEffect } from "react";
import { SessionAction, SessionSelect } from "../../store/modules/session";
import * as Themes from "../../assets/themes";

const useTheme = (silently?: boolean) => {
  const { select, dispatch } = useBehave();
  const theme = select(SessionSelect.theme) ?? "light";
  const pendingTheme = select(SessionSelect.loadingTheme);

  useEffect(() => {
    if (!silently) {
      const t = Themes[theme] as any;
      const style = document.documentElement.style;
      Object.keys(t).forEach((k) => style.setProperty(`--color-${k}`, t[k]));
    }
  }, [theme]);

  useEffect(() => {
    if (silently) {
      if (
        pendingTheme?.loading &&
        pendingTheme?.pending &&
        pendingTheme.pending !== theme
      )
        setTimeout(() => dispatch(SessionAction.changeTheme()), 0);
    }
  }, [pendingTheme?.pending]);

  const changeTheme = (theme: Theme) => dispatch(SessionAction.pendingTheme(theme));
  return { theme, changeTheme };
};

export default useTheme;
