import { PAction } from "behave-react";
import { UserInitialState } from ".";
import select from "../../../utils/array-utils";
import { uncryptData } from "../../../utils/safe-data";

const AsyncReducers = {
  getUser: () => {},
  updateBreathSettings: (
    state: UserState,
    action: PAction<BreathSettings>
  ) => {},
  trackMood: (state: UserState, action: PAction<MoodTrack>) => {},
  deleteMood: (state: UserState, action: PAction<string>) => {},

  addTask: (state: UserState, action: PAction<Task>) => {},
  toogleTask: (state: UserState, action: PAction<string>) => {},
  deleteTask: (state: UserState, action: PAction<string>) => {},

  addNote: (state: UserState, action: PAction<Note>) => {},
  likeNote: (state: UserState, action: PAction<string>) => {},

  saveSession: (state: UserState, action: PAction<BreatheSession>) => {},
};

const Reducers = {
  setUser: (state: UserState, action: PAction<User>) => {
    state.user = action.payload;
  },
  setMetadata: (state: UserState, action: PAction<MetaDataRaw>) => {
    const ks = Object.keys(action.payload);
    const metadata: Partial<MetaData> = {};
    ks.forEach((k) => {
      metadata[k as keyof MetaData] = JSON.parse(
        uncryptData(
          action.payload[k as keyof MetaDataRaw],
          state.user?.email ?? "none"
        )
      );
    });
    state.metadata = metadata as MetaData;
  },
  setMood: (state: UserState, action: PAction<MoodTrack>) => {
    if (!state.metadata?.moods) return;
    select(state.metadata?.moods, "id", action.payload.id).set(action.payload);
  },
  removeeMood: (state: UserState, action: PAction<string>) => {
    if (!state.metadata?.moods) return;
    select(state.metadata?.moods, "id", action.payload).pop();
  },
  updateTodayTasks: (state: UserState, action: PAction<TodayTasks[]>) => {
    if (!state.metadata) return;
    state.metadata.tasks = action.payload;
  },
  setBreathSetings: (state: UserState, action: PAction<BreathSettings>) => {
    if (!state.metadata) return;
    state.metadata.breatheSettings = action.payload;
  },
  setNotes: (state: UserState, action: PAction<Note[]>) => {
    if (!state.metadata) return;
    state.metadata.notes = action.payload;
  },
  updateBreathSessions: (
    state: UserState,
    action: PAction<BreatheSession[]>
  ) => {
    if (!state.metadata) return;
    state.metadata.breathSessions = action.payload;
  },
  purge: () => UserInitialState,
};
const UserReducers = { actions: Reducers, saga: AsyncReducers };
export default UserReducers;
