import { useBehave } from "behave-react";
import { SessionSelect } from "../../store/modules/session";

import * as Icons from "../../assets/icons";

const useThemedIcon = () => {
  const { select } = useBehave();
  const theme = select(SessionSelect.theme);

  return (src: keyof typeof Icons) => {
    const name: string = src + (theme === "dark" ? "Dark" : "");
    return (Icons as any)[name] ?? Icons[src];
  };
};

export default useThemedIcon;
