import { Navigate, Route, Routes } from "react-router-dom";
import { PrivacyPage } from "../../../pages";
import MainPage from "../../../pages/main";
import OnBoarding from "../../../pages/onboarding";

const AuthorizedNavigation = () => {
  return (
    <Routes>
      <Route path="/tutorial" element={<OnBoarding />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/*" element={<MainPage />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AuthorizedNavigation;
