/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useBehave } from "behave-react";
import { VideoPlayer } from "../../components/layout";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles.css";
import { Link, useNavigate } from "react-router-dom";
import useTheme from "../../hooks/use-theme";
import { SessionAction } from "../../store/modules/session";

const Titles = [
  {
    title1: "Your mindful corner of the internet",
    msg: "The best way to be more mindful and happy in just 5 minutes per day.",
  },
  {
    title1: "Mood tracking & Journaling",
    msg: "Mood tracking can help you better understand yourself and work to improve your mental health. Your data is encrypted and automatically backed up.",
  },
  {
    title1: "Guided breathing",
    msg: "Reduce stress and find peace in the chaos of life.",
  },
  {
    title1: "Daily quotes",
    msg: "Start your day with a positive message that will carry you through the day.",
  },
];

const OnBoarding = () => {
  const [page, setPage] = useState(0);
  const theme = useTheme();
  const ref = useRef<Carousel>(null);
  const { dispatch } = useBehave();
  const navigate = useNavigate();

  const endOnboarding = () => {
    dispatch(SessionAction.onboardingDone());
    navigate("/");
  };

  return (
    <div className="fh fw col ch cv">
      <div className="container" key="video-intro">
        <VideoPlayer />
      </div>
      <div className="fh fillw  onboarding">
        <div className="fillh fillw col cv  ch  container">
          <Carousel
            className="carousel"
            showIndicators={false}
            showStatus={false}
            ref={ref}
            showThumbs={false}
            showArrows={false}
            onChange={(n) => setPage(n)}
          >
            <div className="cont">
              <img
                className="introImage"
                alt="intro"
                src={`/assets/videos/onboarding-0_${theme.theme}.png`}
              />
            </div>
            <div className="cont">
              <video
                autoPlay
                muted
                loop
                controls={false}
                src="/assets/videos/onboarding-1.mp4"
              />
            </div>
            <div className="cont">
              <video
                autoPlay
                muted
                loop
                controls={false}
                src="/assets/videos/onboarding-2.mp4"
              />
            </div>
            <div className="cont">
              <video
                autoPlay
                muted
                loop
                controls={false}
                src="/assets/videos/onboarding-3.mp4"
              />
            </div>
          </Carousel>

          <div className="col ch page" style={{ flex: 0.6 }}>
            <h2 className="accent">{Titles[page].title1}</h2>

            <p
              className="mv-m fillw"
              style={{ textAlign: page === 0 ? "center" : "justify" }}
              dangerouslySetInnerHTML={{ __html: Titles[page].msg }}
            ></p>
          </div>
          <button
            className="mv-m"
            onClick={() =>
              page === Titles.length - 1
                ? endOnboarding()
                : ref.current?.increment()
            }
          >
            {page === 0
              ? "Take tour"
              : page === Titles.length - 1
              ? "Start"
              : "Next"}
          </button>

          <p className="st point" onClick={endOnboarding}>
            Leave tutorial
          </p>
        </div>
      </div>
    </div>
  );
};

export default OnBoarding;
