import { useBehave } from "behave-react";
import moment from "moment";
import Calendar, { CalendarTileProperties } from "react-calendar";
import "./styles.css";
import { UserSelect } from "../../../../store/modules/user";
import { useEffect, useState } from "react";
import useThemedIcon from "../../../../hooks/use-themed-icon";

interface CalendarPageProps {
  onDateChange: (dadte: string) => void;
}

const CalendarPage = ({ onDateChange }: CalendarPageProps) => {
  const { select } = useBehave();
  const [date, setDate] = useState(moment().format("MM-DD-yyyy"));
  const moods = select(UserSelect.metadata)?.moods ?? [];
  const icon = useThemedIcon();

  useEffect(() => {
    onDateChange(date);
  }, [date]);

  const compareDate = (tile: CalendarTileProperties) => {
    for (let index = 0; index < moods.length; index++) {
      const m = moods[index];
      if (
        moment(tile.date.toISOString()).format("MM-DD-yyyy") ===
        moment(new Date(m.datePrecise).toISOString()).format("MM-DD-yyyy")
      ) {
        return (
          <div className="doneDay">
            <img src={icon("check")} alt="check" />
            <p>{tile.date.getDate()}</p>
          </div>
        );
      }
    }

    return <div />;
  };

  return (
    <div className="row  calendarPage ">
      <div className="col">
        <h3 className="mv-m">Calendar</h3>
        <Calendar
          locale="en"
          onClickDay={(s) =>
            setDate(moment(s.toISOString()).format("MM-DD-yyyy"))
          }
          tileContent={compareDate}
          maxDetail="month"
          minDetail="month"
          tileClassName="calendarTile"
        />
        <div className="col g-s">
          <div className="row insightsBtn g-s ph-m pv-s cv point">
            <img className="icon s" alt="icon" src={icon("insights")} />
            <p className="bold">Insights</p>
          </div>
          <p className="qt mh-s">Coming soon</p>
        </div>
      </div>
    </div>
  );
};

export default CalendarPage;
