import { useBehave } from "behave-react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserAction, UserSelect } from "../../store/modules/user";

const useMemberstack = () => {
  const { select, dispatch } = useBehave();
  const [memberstack, setMemberstack] = useState<Memberstack>();
  const user = select(UserSelect.user);
  const metadata = select(UserSelect.metadata);
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if ((window as any)?.memberstack) {
      try {
        setMemberstack((window as any)?.memberstack);
        const ms = (window as any)?.MemberStack;

        ms?.reload?.();

        ms?.onReady
          .then((member: User) => {
            dispatch(UserAction.setUser(member));
            if (!user?.getMetaData) setLoading(false);
            else return user.getMetaData();
          })
          .then((data: MetaDataRaw) => {
            dispatch(UserAction.setMetadata(data));
            setLoading(false);
          })
          .catch(() => setLoading(false));
      } catch (e) {}
    }
  }, [location]);

  return { memberstack, user, metadata, loading };
};

export default useMemberstack;
