import { useBehave } from "behave-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAirtable from "../../hooks/use-airTable";
import useMemberstack from "../../hooks/use-memberstack";
import useTheme from "../../hooks/use-theme";
import { SessionSelect } from "../../store/modules/session";
import AuthorizedNavigation from "./authorized";
import UnauthorizedNavigation from "./unauthorized";

const Navigation = () => {
  const { user, loading } = useMemberstack();
  const { select } = useBehave();
  const onboarding = select(SessionSelect.onboardingDone);
  const navigate = useNavigate();
  useTheme();
  useAirtable();

  useEffect(() => {
    !onboarding && navigate("/tutorial");
  }, []);

  return user?.loggedIn ? <AuthorizedNavigation /> : <UnauthorizedNavigation />;
};

export default Navigation;
