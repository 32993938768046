import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { VideoPlayer } from "../../components/layout";
import useThemedIcon from "../../hooks/use-themed-icon";
import "./styles.css";
const SignupPage = () => {
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    (window as any)?.MemberStack?.reload();
  }, []);

  const image = useThemedIcon();

  return (
    <div className="fh fw col ch cv">
      <div className="container" key="video-intro">
        <VideoPlayer />
      </div>

      <div className="signupContainer ch cv fh fw col absolute">
        <form
          data-ms-form="signup"
          data-ms-membership="624612d9feaaa10004bdd5ce"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="col p-s">
            <Link className="close" to="/">
              <img
                src={image("close")}
                className="icon  xs hoverable"
                alt="close-icon"
              />
            </Link>
            <h4 className="xl bold center mv-l">Gardi</h4>
            <h3>Sign up</h3>
            <h6 className="mv-xs">
              Welcome to Gardi! Please enter your details.
            </h6>
            <span className="mv-s" />
            <input
              required
              data-ms-member="username"
              className="mv-s"
              placeholder="Username"
            />
            <input
              required
              data-ms-member="email"
              className="mv-s"
              placeholder="Email"
            />
            <div className="row cv relative">
              <input
                className="f1 mv-s min0"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                data-ms-member="password"
              />
              <img
                onClick={() => setShowPassword(!showPassword)}
                src={image("password")}
                className="icon absolute passwordIcon xs hoverable"
                alt="password"
              />
            </div>
            <span className="mv-m" />
            <button>Sign up</button>
            <p className="xs center bold">
              By continuing, you agree to Gardi's
            </p>
            <p className="center xs bold">
              <a href="/privacy">Terms of Use</a> &{" "}
              <a href="/privacy">Privacy Policy</a>
            </p>

            <p className="xl semi mv-l center">
              Already have an account?
              <Link to="/login" className="mh-xs bold">
                Log in
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignupPage;
