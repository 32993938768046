import { useRef } from "react";
import useOnClickOutside from "../../../../hooks/use-clickOutside";
import "./styles.css";

interface SharePopupProps {
  onClose: () => void;
  onShare: () => void;
  quote: string;
}

const SharePopup = ({ onClose, onShare, quote }: SharePopupProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, onClose);

  return (
    <div className="col ch cv sharePopupBack">
      <div className="sharePopup col ch g-m pv-l ph-xl" ref={ref}>
        <h4 className="center">Share</h4>
        <span />
        <p>My daily quote from Gardi.io "{quote}"</p>
        <span />
        <span />
        <button className="xs" onClick={onShare}>
          Share on Twitter
        </button>
      </div>
    </div>
  );
};

export default SharePopup;
