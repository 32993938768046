import { useBehave } from "behave-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BreatheAnimation, DropDown } from "../../components/layout";
import { UserAction, UserSelect } from "../../store/modules/user";
import { UID } from "../../utils/id-generator";
import "./styles.css";

const SECONDS = [
  "1 sec",
  "2 sec",
  "3 sec",
  "4 sec",
  "5 sec",
  "6 sec",
  "7 sec",
  "8 sec",
  "9 sec",
  "10 sec",
  "11 sec",
  "12 sec",
  "13 sec",
  "14 sec",
  "15 sec",
  "16 sec",
  "17 sec",
  "18 sec",
  "19 sec",
  "20 sec",
  "21 sec",
  "22 sec",
  "23 sec",
  "24 sec",
  "25 sec",
  "26 sec",
  "27 sec",
  "28 sec",
  "29 sec",
  "30 sec",
];

const COLORS = [
  { name: "White", value: "#fff" },
  { name: "Black", value: "#212121" },
  { name: "Green", value: "#71C562" },
  { name: "Blue", value: "#315399" },
  { name: "Orange", value: "#F99F38" },
];

const BreathePage = () => {
  const { select, dispatch } = useBehave();
  const settings = select(UserSelect.breatheSettings);
  const [sessionState, setSessionState] = useState<
    "settings" | "running" | "ended"
  >("settings");
  const [sessionStart, setSessionStart] = useState(0);
  const navigate = useNavigate();

  const handleSessionClose = (session: BreatheSession) => {
    setSessionState("ended");
    dispatch(UserAction.saveSession(session));
  };

  return (
    <div className="fh fw col ch cv overlay breathePage fade-in">
      {sessionState === "settings" && (
        <div className="col sw">
          <h3>Take a moment to breathe</h3>
          <span className="mv-m" />
          <p className="xl">Session length</p>
          <DropDown
            selected={Math.floor(settings.lenght / 60 - 1)}
            className="mv-m"
            options={[
              "1 min",
              "2 min",
              "3 min",
              "4 min",
              "5 min",
              "6 min",
              "7 min",
              "8 min",
              "9 min",
              "10 min",
            ]}
            placeholder="4 min"
            onSelect={(e) => {
              dispatch(
                UserAction.updateBreathSettings({
                  ...settings,
                  lenght: (e + 1) * 60,
                })
              );
            }}
          />
          <div className="row cv">
            <p className="xl f1">Breath in</p>
            <DropDown
              selected={settings.in - 1}
              className="mv-xs"
              options={SECONDS}
              placeholder="3 sec"
              onSelect={(e) => {
                dispatch(
                  UserAction.updateBreathSettings({ ...settings, in: e + 1 })
                );
              }}
            />
          </div>
          <div className="row cv">
            <p className="xl f1">Hold breath</p>
            <DropDown
              selected={settings.hold - 1}
              className="mv-xs"
              options={SECONDS}
              placeholder="3 sec"
              onSelect={(e) => {
                dispatch(
                  UserAction.updateBreathSettings({ ...settings, hold: e + 1 })
                );
              }}
            />
          </div>
          <div className="row cv">
            <p className="xl f1">Breath out</p>
            <DropDown
              className="mv-xs"
              options={SECONDS}
              selected={settings.out - 1}
              placeholder="3 sec"
              onSelect={(e) => {
                dispatch(
                  UserAction.updateBreathSettings({ ...settings, out: e + 1 })
                );
              }}
            />
          </div>
          <div className="row cv">
            <p className="xl f1">Wave color</p>
            <div className="relative">
              <div
                className="colorPreview"
                style={{ backgroundColor: settings.color }}
              />
              <DropDown
                className="mv-xs"
                options={COLORS.map((e) => e.name)}
                placeholder="Black"
                selected={COLORS.findIndex((e) => e.value === settings.color)}
                onSelect={(e) => {
                  dispatch(
                    UserAction.updateBreathSettings({
                      ...settings,
                      color: COLORS[e].value,
                    })
                  );
                }}
              />
            </div>
          </div>
          <div className="col ch mv-xl">
            <button
              className="mv-l"
              onClick={() => {
                setSessionState("running");
                setSessionStart(Date.now());
              }}
            >
              Start breathing
            </button>
          </div>
        </div>
      )}
      {sessionState === "running" && (
        <div className="col ch cv fh relative">
          <BreatheAnimation onComplete={handleSessionClose} />
          <button
            className="close mv-l"
            onClick={() => {
              handleSessionClose({
                id: UID(),
                date: moment().format("MM-DD-yyyy"),
                duration: (Date.now() - sessionStart) / 1000,
              });
            }}
          >
            Close early
          </button>
        </div>
      )}
      {sessionState === "ended" && (
        <div className="col ch cv fh relative ">
          <h2>You completed the breathing session!</h2>
          <p className="st">This session has been saved in the calendar.</p>
          <button className="mv-xl" onClick={() => navigate("/")}>
            Go to the homepage
          </button>
        </div>
      )}
    </div>
  );
};

export default BreathePage;
