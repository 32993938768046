import "./styles.css";
import cx from "classnames";
import useTheme from "../../../hooks/use-theme";

const ThemeSelector = () => {
  const { theme, changeTheme } = useTheme(true);

  const darkNames = cx({ optionSelected: theme === "dark" });
  const lightNames = cx({ optionSelected: theme === "light" });

  return (
    <div className="ph-s themeSelector row ">
      <p className={lightNames} onClick={() => changeTheme("light")}>
        Light
      </p>
      <p className={darkNames} onClick={() => changeTheme("dark")}>
        Dark
      </p>
      <div style={{ left: theme === "light" ? 0 : "calc(50% - 4px)" }} />
    </div>
  );
};

export default ThemeSelector;
