import { PAction } from "behave-react";
import { SessionInitialState } from ".";

const AsyncReducers = {};

const Reducers = {
  changeTheme: (state: SessionModuleState) => {
    state.theme = state.loaders.theme?.pending || "light";
    state.loaders.theme!.loading = false;
  },
  pendingTheme: (state: SessionModuleState, action: PAction<Theme>) => {
    state.loaders.theme = { pending: action.payload, loading: true };
  },
  setQuotes: (state: SessionModuleState, action: PAction<DailyQuote[]>) => {
    state.quotes = action.payload;
  },
  setTasks: (state: SessionModuleState, action: PAction<TaskAction[]>) => {
    state.tasksActions = action.payload;
  },
  onboardingDone: (state: SessionModuleState) => {
    state.onboardingDone = true;
  },
  purge: (state: SessionModuleState) => ({
    ...SessionInitialState,
    theme: state.theme,
  }),
};

const SessionReducers = { actions: Reducers, saga: AsyncReducers };
export default SessionReducers;
