import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useThemedIcon from "../../hooks/use-themed-icon";
import cx from "classnames";
import "./styles.css";
import { useBehave, useTask, useTaskCallback } from "behave-react";
import { SessionSelect } from "../../store/modules/session";
import { UserAction, UserSelect } from "../../store/modules/user";
import moment from "moment";
import { Snackbar } from "../../components/layout";
import SharePopup from "./components/add-task-popup";
const DailyQuote = () => {
  const { select, dispatch } = useBehave();
  const image = useThemedIcon();
  const navigate = useNavigate();
  const data = select(UserSelect.metadata);
  const [writeNote, setWriteNote] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [share, showShare] = useState(false);
  const [note, setNote] = useState("");

  const queryParams = new URLSearchParams(window.location.search);
  const date = queryParams.get("day");
  const saving = useTask("creating", "note");
  const quote = select(SessionSelect.dailyQuote);
  const dayNote = data?.notes?.find(
    (e) =>
      (date ?? moment().format("MM-DD-yyyy")) ===
      moment(e.date, "MM-DD-yyyy").format("MM-DD-yyyy")
  );

  useTaskCallback(saving, {
    success: () => (date ? navigate("/mood") : handleComplete()),
  });

  const handleComplete = () => {
    setWriteNote(false);
    setShowSnackbar(true);
    setTimeout(() => {
      setShowSnackbar(false);
    }, 3000);
  };

  const handleSave = () => {
    dispatch(
      UserAction.addNote({
        comment: note,
        date: date ?? moment().format("MM-DD-yyyy"),
      })
    );
  };

  useEffect(() => {
    setNote(dayNote?.comment ?? "");
  }, [dayNote]);

  return (
    <div className="fh fw col ch cv overlay dailyQuote fade-in">
      <div className="col sw ch">
        <img
          src={image("close")}
          className="icon xs hoverable close"
          alt="close-icon"
          onClick={() => navigate("/")}
        />
        {writeNote ? (
          <div className="col fillw">
            <h3 className="mv-m center">Write a note</h3>
            <p className="quote center mv-l active">“{quote?.quote}”</p>

            <div className="col fillw mv-m">
              <textarea
                value={note}
                className="fillw noteWrite"
                placeholder="Start writing here..."
                onChange={(e) => setNote(e.target.value)}
              />
              <div className="row mv-l">
                <button
                  type="reset"
                  className="secondary"
                  onClick={() => {
                    setWriteNote(false);
                    setNote("");
                  }}
                >
                  Back
                </button>
                <span className="f1" />
                <button type="submit" onClick={() => handleSave()}>
                  Save
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="col ch fillw">
            <h3 className="mv-m center">Daily quote</h3>
            <p className="quote center mv-l">“{quote?.quote}”</p>
            <div className="row cv actions mv-l fillw">
              <div
                onClick={() =>
                  dispatch(
                    UserAction.likeNote(date ?? moment().format("MM-DD-yyyy"))
                  )
                }
              >
                <img
                  src={image(dayNote?.like ? "likeFull" : "like")}
                  className="icon  s hoverable"
                  alt="close-icon"
                />
              </div>
              <div onClick={() => setWriteNote(!writeNote)}>
                <img
                  src={image("note")}
                  className="icon  s hoverable"
                  alt="close-icon"
                />
              </div>
              <div onClick={() => showShare(true)}>
                <img
                  src={image("share")}
                  className="icon  s hoverable"
                  alt="close-icon"
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <Snackbar content="📝  Quote saved succesfully!" show={showSnackbar} />
      {share && (
        <SharePopup
          quote={quote?.quote ?? ""}
          onShare={() => {
            window.open(
              "http://twitter.com/share?text=My daily quote from Gardi.io: “" +
                quote?.quote +
                "“&url=https://gardi.io",
              "_blank"
            );
            showShare(false);
          }}
          onClose={() => showShare(false)}
        />
      )}
    </div>
  );
};

export default DailyQuote;
